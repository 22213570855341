<template>
    <div class="main-wrapper">
     <Navbar/>
     <div class="container">
       <div class="row" >
         <div class="col-3" v-for=" el in dataCatégorie" :key="el.id">
             <div class="popular-course">
				<div class="courses-head">
					<div class="courses-img-main">
						<img src="assets/img/course/c8.jpg" alt="" class="img-fluid w-100">
					</div>
					<div class="courses-aut-img">
						<img src="assets/img/user/user.png" alt="">
					</div>
				</div>
				<div class="courses-body">
					<div class="courses-ratings">
						<ul class="mb-1">
							<li>	<i class="fas fa-star checked"></i> 
							</li>
							<li>	<i class="fas fa-star checked"></i> 
							</li>
							<li>	<i class="fas fa-star checked"></i> 
							</li>
							<li>	<i class="fas fa-star checked"></i> 
							</li>
							<li>	<i class="fas fa-star not-checked"></i> 
							</li>
						</ul>
						<p class="mb-1">Hinata Hyuga</p>
						<h4 class="mb-0">{{el.description}}</h4>
					</div>
				</div>
				<div class="courses-border"></div>
				<div class="courses-footer d-flex align-items-center">
					<div class="courses-count">
						<ul class="mb-0">
							<li><i class="fas fa-user-graduate mr-1"></i> 85</li>
							<li><i class="far fa-file-alt mr-1"></i>5</li>
						</ul>
					</div>
					<div class="courses-amt ml-auto">
						<h3 class="mb-0">$200</h3>
					</div>
				</div>
			</div>
         </div>
       </div>

     </div>
    </div>
</template>

<script>
import Navbar from '@/components/layouts/Navbar'
export default {
  components:{
    Navbar
  },
  props:['idCat'],
    data(){
        return{
         dataCatégorie:[]
        }
    },
   mounted(){
      this.$nextTick(function () {
      this.$http.get('courses/category/'+this.idCat+'/')
        .then(res=>{this.dataCatégorie.push(res.data);
         console.log(res.data)
        })
        console.log(this.dataCatégorie)
        //  this.$router.push({name:'ShowCourseCategory', params: {idCat:id}})
      })
    },

}
</script>

<style>

</style>